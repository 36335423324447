import React, { Component } from "react";
import { enquireScreen } from "enquire-js";
import { injectIntl } from "gatsby-plugin-intl";
// import FAQ from "../../components/FAQ";

import {
  Banner03DataSource,
  CMND01DataSource,
  // FAQDataSource,
  CustomersDataSource
} from "../../data/insurtech.data";

import { ContactFormDataSource } from "../../data/home.data";
import loadable from "@loadable/component";

const Layout = loadable(() => import("../../components/Layout"));
const SEO = loadable(() => import("../../components/SEO"));
const Banner = loadable(() => import("../../components/Banner"));

const ContactForm = loadable(() => import("../../components/Home/ContactForm"));
// const FAQ = loadable(() => import("../../components/FAQ"));
const CMND01 = loadable(() => import("../../components/CMND/CMND01"));
const Customers = loadable(() => import("../../components/Home/Customers"));

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

class OCRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { intl } = this.props;
    return (
      <>
        <SEO
          title={intl.formatMessage({ id: "seo.title_page32" })}
          pathname="/solutions/insurance"
          description="CVS Insurtech là giải pháp thị giác máy tính toàn diện cho ngành bảo hiểm giúp các doanh nghiệp tự động hóa trong xử lý giấy tờ và thủ tục"
          keywords="CVS Insurtech"
        />
        <Layout>
          <Banner
            id="Banner0_3"
            key="Banner0_3"
            dataSource={Banner03DataSource}
            isMobile={this.state.isMobile}
          />
          <Customers
            id="Customers"
            key="Customers"
            dataSource={CustomersDataSource}
            isMobile={this.state.isMobile}
            slidesToShow={5}
          />
          <CMND01
            id="CMND01"
            key="CMND01"
            dataSource={CMND01DataSource}
            isMobile={this.state.isMobile}
          />
          <ContactForm
            id="ContactForm"
            key="ContactForm"
            dataSource={ContactFormDataSource}
            isMobile={this.state.isMobile}
          />
          {/* <FAQ
            id="FAQ"
            key="FAQ"
            dataSource={FAQDataSource}
            isMobile={this.state.isMobile}
          /> */}
        </Layout>
      </>
    );
  }
}

export default injectIntl(OCRPage);
